/* eslint-disable sort-keys */
const i18nGlobal = {
	errorInfoWithSign: {
		1 : 'Если Вы не нажимали на кнопку "Отмена" и не можете найти окно клиентского приложения, проверьте панель задач (обычно она расположена внизу экрана)',
		0 : 'Калі Вы не націскалі на кнопку "Адмена" і не можаце знайсці акно кліенцкага прыкладання, праверце панэль задач (звычайна яна размешчана ўнізе экрана)'
	},
	errorWithFileSignInCP: {
		1 : 'Ошибка при подписании файла, код ошибки ',
		0 : 'Памылка пры падпісанні файла, код памылкі '
	},
	errorWithTextSignInCP: {
		1 : 'Ошибка при подписании текста, код ошибки ',
		0 : 'Памылка пры падпісанні тэкста, код памылкі '
	},
	errorWithCPAccess: {
		1 : 'Ошибка доступа клиентского приложения. Проверьте, запущена ли у вас программа.',
		0 : 'Памылка доступу кліенцкага прыкладання. Праверце, ці запушчана ў вас праграма.'
	},
	nsiModify: {
		1 : 'Управление НСИ',
		0 : 'Кіраванне НДI'
	},
	clearFilters: {
		1 : 'Сбросить фильтры',
		0 : 'Скінуць фільтры'
	},
	citizenAppeals: {
		1 : 'Обращения граждан и юридических лиц',
		0 : 'Звароты грамадзян і юрыдычных асоб'
	},
	fastReport: {
		1 : 'Отчёты',
		0 : 'Справаздачы'
	},
	news: {
		1 : 'Новости',
		0 : 'Навіны'
	},
	contacts: {
		1 : 'Контакты',
		0 : 'Кантакты'
	},
	help: {
		1 : 'Помощь',
		0 : 'Дапамога'
	},
	nri: {
		1 : 'Нормативно-справочная информация',
		0 : 'Нарматыўна-даведачная інфармацыя'
	},
	calendar: {
		1 : 'Календарь',
		0 : 'Каляндар'
	},
	reports: {
		1 : 'Отчёты',
		0 : 'Справаздачы'
	},
	messageTemplates: {
		1 : 'Тип шаблона',
		0 : 'Тып шаблона'
	},
	messageTemplate: {
		1 : 'Шаблон ответа',
		0 : 'Шаблон адказа'
	},
	createMessageTemplate: {
		1 : 'Добавить шаблон',
		0 : 'Дадаць шаблон'
	},
	createTemplateHeader: {
		1 : 'Добавить шаблон сопроводительного письма',
		0 : 'Дадаць шаблон cуправаджальнага ліста'
	},
	editMessageTemplate: {
		1 : 'Редактировать шаблон',
		0 : 'Рэдагаваць шаблон'
	},
	deletePopMessageTemplate: {
		1 : 'Хотите удалить шаблон?',
		0 : 'Хочаце выдаліць шаблон?'
	},
	advancedSearch: {
		1 : 'Расширенный поиск',
		0 : 'Пашыраны пошук'
	},
	find: {
		1 : 'Найти',
		0 : 'Знайсці'
	},
	applyFilters: {
		1 : 'Применить фильтры',
		0 : 'Прымяніць фільтры'
	},
	clear: {
		1 : 'Очистить все',
		0 : 'Ачысціць ўсе'
	},
	lastName: {
		1 : 'Фамилия',
		0 : 'Прозвішча'
	},
	firstName: {
		1 : 'Имя',
		0 : 'Імя'
	},
	fio: {
		1 : 'ФИО',
		0 : 'ФІО'
	},
	middleName: {
		1 : 'Отчество',
		0 : 'Імя па бацьку'
	},
	userName: {
		1 : 'Имя пользователя (логин)',
		0 : 'Імя карыстальніка (лагін)'
	},
	phone: {
		1 : 'Контактный телефон',
		0 : 'Кантактны тэлефон'
	},
	chooseDate: {
		1 : 'Выберите дату',
		0 : 'Выберыце дату'
	},
	confirm: {
		1 : 'Подтвердить',
		0 : 'Пацвердзіць'
	},
	cancel: {
		1 : 'Отменить',
		0 : 'Адмяніць'
	},
	yes: {
		1 : 'Да',
		0 : 'Так'
	},
	no: {
		1 : 'Нет',
		0 : 'Не'
	},
	signEDS: {
		1 : "Прикрепить ЭЦП?",
		0 : "Прымацаваць ЭЛП?"
	},
	notSignEDS: {
		1 : ["ЭЦП не была прикреплена.", "Данный браузер не поддерживает работу с ЭЦП."],
		0 : ["ЭЛП не была прымацаваная.", "Дадзены браўзэр не падтрымлівае працу з ЭЛП."]
	},
	orgName: {
		1 : 'Наименование организации',
		0 : 'Найменне арганізацыі'
	},
	confirmed: {
		1 : 'Подтвержден',
		0 : 'Пацверджаны'
	},
	notConfirmed: {
		1 : 'Не подтвержден',
		0 : 'Не пацверджаны'
	},
	date: {
		1 : 'Дата создания',
		0 : 'Дата стварэння'
	},
	dateEndDue: {
		1 : 'Дата окончательного исполнения',
		0 : 'Дата канчатковага выканання'
	},
	dateEnd: {
		1 : 'Дата исполнения',
		0 : 'Дата выканання'
	},
	startDate: {
		1 : 'Дата стварэння от',
		0 : 'Дата стварэння ад'
	},
	endDate: {
		1 : 'Дата стварэння по',
		0 : 'Дата стварэння да'
	},
	successAction: {
		1 : 'Действие прошло успешно!',
		0 : 'Дзеянне прайшло паспяхова!'
	},
	remove: {
		1 : 'Удалить',
		0 : 'Выдаліць'
	},
	chooseFile: {
		1 : 'Добавить',
		0 : 'Дадаць'
	},
	change: {
		1 : 'Изменить',
		0 : 'Змяніць'
	},
	save: {
		1 : 'Сохранить',
		0 : 'Захаваць'
	},
	isOutsideRB: {
		1 : 'Подача из-за пределов Республики Беларусь',
		0 : 'Падача з-за межаў Рэспублікі Беларусь'
	},
	close: {
		1 : 'Закрыть',
		0 : 'Зачыніць'
	},
	submit: {
		1 : 'Сохранить',
		0 : 'Захаваць'
	},
	jsonUpload: {
		1 : 'Загрузить JSON',
		0 : 'Загрузіць JSON'
	},
	languageBe: {
		1 : 'Белорусский',
		0 : 'Беларуская'
	},
	languageRu: {
		1 : 'Русский',
		0 : 'Руская'
	},
	empty: {
		1 : 'Пусто',
		0 : 'Пуста'
	},
	textRus: {
		1 : 'Текст на русском языке',
		0 : 'Тэкст на рускай мове'
	},
	name: {
		1 : 'Название',
		0 : 'Назва'
	},
	nameTamplate: {
		1 : 'Название шаблона',
		0 : 'Назва шаблону'
	},
	textBel: {
		1 : 'Текст на белорусском языке',
		0 : 'Тэкст на беларускай мове'
	},
	messageSuccess: {
		1 : 'Изменения успешно сохранены.',
		0 : 'Змены паспяхова захаваныя.'
	},
	required: {
		1 : 'Пожалуйста, заполните поле',
		0 : 'Калі ласка, запоўніце поле'
	},
	fileTypeError: {
		1 : 'Недоступный формат файла',
		0 : 'Недаступны фармат файла'
	},
	downloadFileSizeError: {
		1 : 'Размер файла больше {0}МБ',
		0 : 'Памер файла больш {0}МБ'
	},
	downloadFileRulesInfo: {
		1: `Для загрузки доступны файлы  форматов: ".jpg", "jpeg", ".png", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".tif", ".tiff", ".rtf".  
        Размер файла должен быть до {0}МБ `,
		0: `Для загрузкі даступныя файлы фарматаў: ".jpg", "jpeg", ".png", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".tif", ".tiff", ".rtf".
       Памер файла павінен быць да {0}МБ`
	},
	attachFilesTitle: {
		1 : 'Ошибка загрузки файла!',
		0 : 'Памылка загрузкі файла!'
	},
	tasks: {
		1 : 'Задачи',
		0 : 'Задачы'
	},
	position: {
		1 : "Должность",
		0 : "Пасада"
	},
	userEmail: {
		1 : "E-mail пользователя",
		0 : "E-mail карыстальніка"
	},
	doesntChoosed: {
		1 : "Не выбрано",
		0 : "Не выбрана"
	},
	nsi: {
		1 : "НСИ",
		0 : "НДI"
	},
	clean: {
		1 : "Очистить все",
		0 : "Ачысціць усё"
	},
	absent: {
		0 : "Адсутнічае",
		1 : "Отсутствует"
	},
	selectAll: {
		0 : "Выбраць усе",
		1 : "Выбрать все"
	},
	downloadFileStarted: {
		0 : "Пачалася выгрузка файла",
		1 : "Началась выгрузка файла"
	},
	downloadFileError: {
		0 : "Памылка выгрузкі файла",
		1 : "Ошибка выгрузки файла"
	},
	downloadScaleExample: {
		0 : "Спампаваць прыклад шаблону",
		1 : "Скачать пример шаблона"
	},
	coverLetter: {
		0 : "Суправаджальны ліст",
		1 : "Cопроводительное письмо"
	},
	noticeToApplicant: {
		0 : "Апавяшчэнне заяўніку",
		1 : "Уведомление заявителю"
	},
	messageForFile: {
		0 : "Калі ласка, дадайце файл",
		1 : "Пожалуйста, добавьте файл"
	},
	coverLetterInstruction: {
		0 : "Інструкцыя. Націсніце на кнопку 'Спампаваць прыклад шаблону', пачнецца загрузка файла ў сістэму. Пасля запампоўкі файла з прыкладам, вам неабходна змяніць унутры яго шапку з дадзенымі вашай арганізацыяй (на рускай і беларускай мовах). Затым заменіце радок '{увядзіце назоў вашай арганізацыі}' назовам вашай арганізацыі. ВАЖНА!!! Словы на англійскай мове, напрыклад (Position і інш.) змяняць нельга. Пасля таго, як файл быў адрэдагаваны, яго неабходна загрузіць у сістэму (націснуць кнопку 'Дадаць' і абраць гэты файл).",
		1 : "Инструкция. Нажмите на кнопку 'Скачать пример шаблона', начнётся загрузка файла в систему. После скачивания файла с примером, вам необходимо изменить внутри него шапку с данными вашей организацией (на русском и белорусском языках). Затем замените строку '{введите название вашей организации}' названием вашей организации. ВАЖНО!!! Слова на английском языке, например (Position и др.) изменять нельзя. После того, как файл был отредактирован, его необходимо загрузить в систему (нажать кнопку 'Добавить' и выбрать этот файл)."
	},
	noticeToApplicantInstruction: {
		0 : "Інструкцыя. Націсніце на кнопку 'Спампаваць прыклад шаблону', пачнецца загрузка файла ў сістэму. Пасля запампоўкі файла з прыкладам, вам неабходна змяніць унутры яго шапку з дадзенымі вашай арганізацыяй (на рускай і беларускай мовах). ВАЖНА!!! Словы на англійскай мове , напрыклад (Position і інш.) змяняць нельга. Пасля таго, як файл быў адрэдагаваны, яго неабходна загрузіць у сістэму (націснуць кнопку 'Дадаць' і абраць гэты файл).",
		1 : "Инструкция. Нажмите на кнопку 'Скачать пример шаблона', начнётся загрузка файла в систему. После скачивания файла с примером, вам необходимо изменить внутри него шапку с данными вашей организацией (на русском и белорусском языках). ВАЖНО!!! Слова на английском языке, например (Position и др.) изменять нельзя. После того, как файл был отредактирован, его необходимо загрузить в систему (нажать кнопку 'Добавить' и выбрать этот файл)."
	},
	fileNotice: {
		0 : "Няправільны фармат файла!",
		1 : "Неверный формат файла!"
	},
	ecpResolutionIntermediateResult: {
		1 : "Прикрепить ЭЦП к промежуточному результату?",
		0 : "Прымацаваць ЭЛП да прамежкавага выніку?"
	},
	noSigner : {
		1 : "В случае, когда подпись не прикрепляется, поле \"Подписант\" является обязательным для заполнения.", 
		0 : "У выпадку, калі подпіс не прымацоўваецца, поле \"Падпісант\" з'яўляецца абавязковым для запаўнення ."
	},
	dateDelete: {
		1 : 'Дата удаления',
		0 : 'Дата выдалення'
	},
	titleModalEditItem: {
        1: "Редактировать запись",
        0: "Рэдагаваць запіс",
    },
    titleModalAddItem: {
        1: "Добавить запись",
        0: "Дадаць запіс",
    },
	noDataChange: {
        1 : "Данные не были изменены",
        0 : "Дадзеныя не былі зменены"
    },
	nameFormatErrorMessage: {
		1 : 'Поле должно содержать в себе более 1 символа на кириллице и начинаться с заглавной буквы. Также допускаются символы дефиса, апострофа и пробела если за ними идут буквы.',
		0 : 'Поле павінна ўтрымоўваць у сабе больш за 1 знака на кірыліцы і пачынацца з загалоўнай літары. Таксама дапушчаюцца знакі злучка, апострафа і прабелу калі за імі ідуць літары.'
	},
	requiredMessages: {
        1: "Поле обязательно для заполнения",
        0: "Поле абавязкова для запаўнення"
    },
	noDataChange: {
        1 : "Данные не были изменены",
        0 : "Дадзеныя не былі зменены"
    },
	infoTitle : {
		1: `Для загрузки доступны файлы  формата: "docx".  
					Размер файла должен быть до 15 МБ `,
		0: `Для загрузкі даступныя файлы фармату: ".docx".
				 Памер файла павінен быць да 15 МБ`
	}
}

export default i18nGlobal
